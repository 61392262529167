import React from 'react'
import {Link} from 'react-router-dom';
import Context from '../../../components/context';
import {useContext, useState, useEffect} from 'react'
import {pathImgPerfil } from '../../../components/paths'
import {MdKeyboardArrowRight, MdKeyboardArrowLeft , MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import {sinFoto} from '../../../contenido/constantes'


const Subjurisdicciones = (props) => {

  const context = useContext(Context);
  const secreGral=3 
  const col=  'col04';
  const [windowWidth, setWindowWidth]= useState('')
  const [flechaAbajo, setFlechaAbajo]=useState(false)
  
    useEffect(()=>{       
        const handleResize = () => {        
         setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);      
        return () => {
        window.removeEventListener('resize', handleResize);
        };        
    },[])

    useEffect(()=>{
        setFlechaAbajo(windowWidth>10 && windowWidth<=400)
    },[windowWidth])

    var fulltWidth = props.slice == 1 ?  `width39` : `${context.widthSecres} varwidth d-flex justify-content-center` 

    function changeWidth(){       
        context.setWidthSecres(context.widthSecres=='widthcero' ? 'widthauto' : 'widthcero')
    }

    function handleSrc(e){
        e.target.src=`${pathImgPerfil}/${sinFoto}.png`
    }


  return (
        <>
          {context.listaMinHome && context.listaMinHome[1] ?
                //Secretarías
                <div className={`${fulltWidth}`}>
                    <div className={context.widthSecres=='widthcero' ? 'contenedorLateral' : 'flexMasSubj'}>
                    {context.listaMinHome?.filter((unidad)=>unidad.n1=='000' && unidad.idunidad==secreGral)?.map((elem, indice)=>{
                        const estiloH3 = elem?.unidad?.length < 40 ? 'fontSizeMayor' : 'fontSizeMenor' // Cambia el tamaño de la fuente según la longitud del texto.
                        let n1= elem?.n1
                        let n2= elem?.n2
                        let n1yn2= (n1 && n2) ? n1+n2 : null  
                        const nombreImg=  n1yn2 && elem?.versionImgP ? `${n1yn2}.png?v=${elem?.versionImgP}` : `${sinFoto}.png`  

                        return(                            
                                <div key={indice} className={`card01 bg-white d-flex bg-shadow4 p-1 ${col}`}>
                                    <Link className={`${elem?.clase} linkSubjuri`} to={`ministerios/${elem?.n1}`}>      
                                        { nombreImg ?
                                            <img className="imgMin" 
                                            alt={elem?.autoridad}
                                            onError={handleSrc}
                                            src={`${pathImgPerfil}/${nombreImg}`}
                                            title={elem?.unidad} /> 
                                            :
                                            null
                                        }
                                        <div className="textColumn text-left px-0 py-0">
                                            <h3 className={`${estiloH3} bg-oscuro`}>
                                                {elem?.unidad}
                                            </h3>
                                            <h4 className="px-3 text-center jurboxmin ft-lt-black">
                                                {elem?.autoridad}
                                            </h4>                             
                                        </div>
                                    </Link>                                 
                                </div> 
                        )
                        })
                    }
                    <div className={`${context.widthSecres} varwidth`}>
                       {context.listaMinHome.filter((unidad)=>unidad.n1=='000' && unidad.idunidad>secreGral)?.map((elem, indice)=>{                        
                        const estiloH3 = elem?.unidad?.length < 40 ? 'fontSizeMayor' : 'fontSizeMenor' // Cambia el tamaño de la fuente según la longitud del texto.
                        const sizeAut = elem?.unidad?.length < 40 ? 'fsizeMayorAut' : 'fsizeMenorAut'
                        let n1= elem?.n1
                        let n2= elem?.n2
                        let n1yn2= (n1 && n2) ? n1+n2 : null 
                        const nombreImg2= n1yn2 && elem?.versionImgP ? `${n1yn2}.png?v=${elem?.versionImgP}` : `${sinFoto}.png`    

                        return(
                            <div key={indice} className={`card01 bg-white d-flex bg-shadow4 p-1 ${col}`}>
                                <Link className={`${elem?.clase} linkSubjuri`} to={`ministerios/${elem?.n1}`}>      
                                    {nombreImg2 ?
                                        <img className="imgMin" 
                                        alt={elem?.autoridad}
                                        onError={handleSrc}
                                        src={`${pathImgPerfil}/${nombreImg2}`}
                                        title={elem?.unidad} /> 
                                        : 
                                        null
                                    }
                                    <div className="textColumn text-left px-0 py-0">
                                        <h3 className={`${estiloH3} bg-oscuro`}>
                                            {elem?.unidad}
                                        </h3>
                                        <h4 className={`${sizeAut} px-3 text-center jurboxmin ft-lt-black`}>
                                            {elem?.autoridad}
                                        </h4>                             
                                    </div>
                                </Link>                                 
                            </div> 
                        )
                        })
                    }
                    </div>
                    {props.slice==1?
                    <div className={`mostrarsubj ${context.widthSecres=='widthcero' ? 'posAbsolute ' : ''}`} onClick={changeWidth}>
                        <div className="buttonVerSubj">
                           {context.widthSecres=='widthcero' ?
                            <div className="d-flex align-items-center fontSizeMenor">                            
                                <div className="px-1 py-0">+ Secretarías de Presidencia</div>
                                {flechaAbajo == true ? <MdKeyboardArrowDown size={20} /> : <MdKeyboardArrowRight size={20} />}
                            </div>
                           : 
                              flechaAbajo? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowLeft size={20}/>                        
                            }
                        </div>
                    </div> 
                    :
                    ''}
                    </div>
                </div>
                :
                <></>
          }  
     </>
  )
}

export default Subjurisdicciones