
//HEADER
export const headTitulo = '';
export const headVolanta = 'Sitio oficial que brinda información sobre las estructuras organizativas de la Administración Pública Nacional centralizada, descentralizada, empresas y sociedades del Estado. Te permite conocer los objetivos y responsabilidades de cada Área de Gobierno, organigramas, normativas y autoridades designadas en los organismos públicos.';

//Seccion 1
export const s01_titulo = 'El Portal en números';
export const s01_foot01 = 'Datos publicados al ';
export const s01_foot02 = ' - Información en proceso de análisis y consolidación';
export const s0102_titulo = 'Gráficos Estadísticos';
//Seccion 2
export const s02_titulo = 'Información Útil de la Administración Pública Nacional';
//Secion 2.1 Descarga CSV Estructura vigente
export const s02_0101_at01 = 'Estructura y Autoridades de la APN - Descarga CSV (Delimitado por comas) - VIGENTE -';
export const s02_0101_h401 = 'Estructura y Autoridades de la Administración Pública Nacional <br>- VIGENTE -';
export const s02_0101_p01 = 'Vigencia: '.UFA_WEB;
//Secion 2.2 - Organigrama APN
export const s02_0102_at01 = 'Administración Central, Organismos Descentralizados y Otros Entes del Sector Público Nacional';
export const s02_0102_h401 = 'Organigrama';
export const s02_0102_p01 = 'Organigrama de la Administración Pública Nacional';
//Secion 2.3 CSV Gestion Anterior
export const s02_0103_at01 = 'Estructura y Autoridades de la APN - Descarga CSV (Delimitado por comas) - Vigente al 09-10-2019';
export const s02_0103_h401 = 'Estructura y Autoridades de la Administración Pública Nacional <br>- HISTÓRICO -';
export const s02_0103_p01 = 'Vigencia: 09-12-2019';

//Seccion 3
export const s03_titulo = 'Acerca de Mapa del Estado';
export const s03_0101_p01 = 'El Portal Mapa del Estado publica la composición de la estructura organizativa de la Administración Pública Nacional Centralizada y Descentralizada (se entiende por Administración Pública Nacional lo establecido en el inciso “a” del art. 8° de la Ley N° 24.156), en el marco de la normativa vigente y de acuerdo con la responsabilidad primaria y acciones de la Dirección Nacional de Diseño Organizacional de la Jefatura de Gabinete de Ministros. ';
export const s03_0101_p02 = 'La información sobre la estructura se complementa con el dato relativo a la designación de cada funcionario/a publicada en el Boletín Oficial de la República Argentina. Los datos relativos a las Empresas y Sociedades del Estado y Entes Públicos excluidos expresamente de la Administración Nacional, integrantes del Sector Público Nacional (incisos “b” y “c” del art. 8° de la Ley N° 24.156), surgen de información no exhaustiva recopilada por la Dirección de Mapa del Estado.' 
export const s03_0101_p02b = 'La Dirección Nacional de Diseño Organizacional no tiene competencias relativas a la estructura organizativa de Empresas y Sociedades del Estado, Entes Públicos excluidos expresamente de la Administración Nacional, ni al registro y publicación de designaciones de funcionarios de la Administración Pública Nacional. De allí que, de la información referida que se encuentra publicada en el Portal Mapa del Estado no puedan seguirse derechos ni obligaciones.'
export const s03_0101_p03 = 'Los contenidos publicados en el Portal Mapa del Estado surgen de la Base Integrada de Mapa del Estado (BIME) con información respaldada por normativa publicada en el Boletín Oficial de la República Argentina. En los casos en que las unidades integrantes del Sector Público Nacional comunican o suministran mediante comunicación oficial directamente a la Dirección Nacional de Diseño Organizacional o a la Dirección de Mapa del Estado información relativa a normativa no publicada se analiza su pertinencia previamente a su incorporación al Portal.';
export const s03_0101_p04 = 'El contenido del Portal Mapa del Estado se actualiza periódicamente con la información correspondiente a las modificaciones normativas relevadas.';


export const notaAclaratoria= ['Nota Aclaratoria ', 
'Sobre el contenido del Portal Mapa del Estado:',
'A. Responsabilidad Primaria y acciones de la Dirección Nacional de Diseño Organizacional referida a Mapa del Estado',
'La Dirección Nacional de Diseño Organizacional tiene entre sus acciones: “desarrollar un sistema de información sobre la estructura organizativa de la Administración Pública Nacional centralizada y descentralizada, administrarlo y supervisar su actualización, en especial la Base Integrada de Mapa del Estado (BIME) y el portal Web de Mapa del Estado.”',
'El Portal Mapa del Estado, https://mapadelestado.jefatura.gob.ar/, reproduce una versión escalar del Organigrama de Aplicación de la Administración Pública Nacional Centralizada y Descentralizada, de conformidad con la normativa vigente, y publica la información correspondiente a sus unidades organizativas.',
'B. Alcance de la información publicada en el Portal Mapa del Estado',
'La información relativa a las unidades organizativas de la Administración Pública Nacional Centralizada y Descentralizada proviene de normativa vigente aprobada con intervención de la Dirección Nacional de Diseño Organizacional, en cumplimento de su responsabilidad primaria y acciones.',
'La recopilación (no exhaustiva) de información relativa a los/las funcionarios/as designados/as que ostentan la titularidad de las unidades integrantes de la estructura organizativa de la Administración Pública Nacional Centralizada y Descentralizada así como la información relativa a otras unidades integrantes del Sector Público Nacional y a la designaciones de los y las titulares de las mismas surge del relevamiento realizado por la Dirección de Mapa del Estado a partir de su publicación en el Boletín Oficial de la República Argentina y de las comunicaciones oficiales remitidas directamente a la Dirección Nacional de Diseño Organizacional y a la Dirección de Mapa del Estado por los organismos.',
'C. Criterio editorial del Portal Mapa del Estado',
'El criterio editorial establecido por la Dirección Nacional de Diseño Organizacional para los contenidos publicados en el Portal Mapa del Estado consiste en la incorporación exclusiva a la Base Integrada de Mapa del Estado (BIME) de la información respaldada por normativa publicada en el Boletín Oficial de la República Argentina. Asimismo, de la información respaldada por la normativa no publicada, que las unidades integrantes del Sector Público Nacional realicen mediante comunicación oficial directamente a esta Dirección Nacional o a la Dirección de Mapa del Estado.',
'La experiencia recogida por la Dirección de Mapa del Estado indica que la información relativa a renuncias de funcionarios/as suele no publicarse -y no comunicarse a la Dirección Nacional de Diseño Organizacional- por lo que la misma puede ofrecer posibles inexactitudes.',
'D. Protocolo de actualización del Portal Mapa del Estado',
'La Base Integrada de Mapa del Estado (BIME) se actualiza periódicamente con la información correspondiente a las modificaciones normativas relevadas y se impacta sobre el Portal Mapa del Estado: https://mapadelestado.jefatura.gob.ar/.',
'La Dirección de Mapa del Estado conserva en su archivo las versiones anteriores correspondientes a cada publicación, con su fecha respectiva, en formato .CSV',
'Cookies e identificadores anónimos',
'El Administrador utiliza diferentes tecnologías para recoger y almacenar datos cuando el Usuario accede a un servicio provisto por el Gobierno Nacional, incluyendo el envío de una o varias cookies o de identificadores anónimos al dispositivo del Usuario.']
//Seccion 4
export const s04_titulo = 'Datos';

export const textbuscador='¿Qué estás buscando?'
export const placeholder='Unidades Organizativas y Autoridades vigentes'
